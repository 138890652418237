.nav-link {
  position: relative;
  border-radius: 4px;
  width: 100%;
  font-weight: 500;
  line-height: 16px;
  color: var(--chakra-colors-gray-500);
}

.nav-link svg path {
  fill: var(--chakra-colors-gray-500);
}

.nav-link.active {
  background: var(--chakra-colors-gray-50);
  color: black;
  opacity: 1;
}

.nav-link:hover {
  background: var(--chakra-colors-gray-50);
  opacity: 1;
}

.nav-link.active:before {
  content: '';
  position: absolute;
  display: block;
  left: -20px;
  top: 8px;
  height: 16px;
  width: 4px;
  border-radius: 0 4px 4px 0;
  background: black;
}

.nav-link.active svg path {
  fill: black;
}

.nav-new-event {
  background: var(--chakra-colors-blue-400);
}

.nav-new-event:hover {
  background: var(--chakra-colors-blue-500);
  cursor: pointer;
}

.jurnee-logo {
  display: flex;
  align-items: center;
  height: 34px;
}

@media (max-height: 580px) {
  .jurnee-logo {
    display: none;
  }

  .jurnee-logo + .nav-link {
    margin-top: 0;
  }
}

@media (max-height: 520px) {
  #intercom-launcher {
    display: none;
  }
}
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker {
  border: 1px solid var(--chakra-colors-gray-200);
  box-shadow: var(--chakra-shadows-lg);
}

.react-datepicker__header {
  background-color: var(--chakra-colors-gray-50);
  border-bottom: 1px solid var(--chakra-colors-gray-200);
  padding: 18px 0;
}

.react-datepicker__navigation {
  top: 13px;
}

.react-datepicker__day--selected {
  background-color: var(--chakra-colors-blue-400);
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/inter/regular.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/inter/medium.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/inter/bold.woff2") format("woff2");
}

html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: var(--chakra-colors-gray-10);
}

#app-container {
  width: 100%;
  height: 100%;
  background: var(--chakra-colors-gray-10);
}

main {
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  overflow-y: auto;
  margin: 0 !important;
  position: relative;
}

.chakra-table tbody tr:last-child td {
  border-bottom: 0;
}

.react-select-small div {
  border-radius: 4px;
}

.react-select-small > div > div:first-of-type > div:first-child {
  padding: 0 10px;
}

.react-select-small > div > div:first-of-type > div:nth-child(2) > div {
  padding: 0 12px;
}

.react-select-small > div > div {
  min-height: 32px;
}

.react-multi-creatable-select-small div {
  border-radius: 4px;
}

.react-multi-creatable-select-small > div > div:first-of-type > div:first-child {
  padding: 0 2px;
}

.react-multi-creatable-select-small > div > div {
  min-height: 32px;
}

ol, ul {
  list-style-position: inside;
  margin-inline-start: 0.5em;
  margin-left: 0.5em;
}

.experiences-map-marker-card .mapboxgl-popup-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 12px;
  height: 86px;
  min-width: 320px;
  max-width: 320px;
  border: 1px solid var(--chakra-colors-gray-200);
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Inter';
  box-shadow: none;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.mapboxgl-popup-tip {
  display: none;
}